import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "reactstrap";

import { AutosuggestWrapper } from "Components/Forms/AutosuggestWrapper";
import SelectFieldOption from "Models/SelectFieldOption";
import { conditionalStyles } from "Helpers/conditionalStyles";

import { Keys } from "Translation/Setup";
import { RailFlyClassField } from "./RailFlyClassField";
import styles from "../RailFly.module.scss";
import i18next from "i18next";
import { TrainDirection } from "../../../../Models/TravelSeller/Models/TS_AddonTrain";
import { RailFlyDirectionField } from "./RailFlyDirectionField";

interface Props {
  trainConnections: SelectFieldOption[];
  flightConnections : SelectFieldOption[];
  destination: string;
  destinationLabel: string;
  returningDeparture: string;
  origin: string;
  departingStationCode: string;
  departingClassCode: number;
  returningStationCode: string;
  returningClassCode: number;
  direction: TrainDirection;
  onChangeDepartingStationCode: (value: string) => void;
  onChangeDepartingClassCode: (value: number) => void;
  onChangeDestinationCode: (value: string) => void;
  onChangeReturningDepartureCode: (value: string) => void;
  onChangeReturningStationCode: (value: string) => void;
  onChangeReturningClassCode: (value: number) => void;
  onChangeDirection: (value: TrainDirection) => void;
}

const trainClasses: string[] = [
  i18next.t(Keys.WORKFLOW.Train.trainClasses.first),
  i18next.t(Keys.WORKFLOW.Train.trainClasses.second),
];

const css = conditionalStyles(styles);

export const RailFlySelection = (props: Props) => {
  const trainConnections = props.trainConnections || [];
  const flightConnections = props.flightConnections || [];

  const { t } = useTranslation();

  return (
    <div>
      <h5>
        {t(Keys.WORKFLOW.Train.trainConnection.departureArrival.headline)}
      </h5>
      <Row>
        <Col xs="4" md="5">
          <Row className="mb-3">
            <Col>
              <ButtonGroup>
                <Button
                className={styles.noShadow}
                  color="primary"
                  size="sm"
                  active={props.direction === TrainDirection.ROUNDTRIP}
                  onClick={() =>
                    props.onChangeDirection(TrainDirection.ROUNDTRIP)
                  }
                >
                  {t(Keys.WORKFLOW.Train.trainDirection.roundtrip)}
                </Button>
                <Button
                  size="sm"
                  className={styles.noShadow}
                  color="primary"
                  active={props.direction !== TrainDirection.ROUNDTRIP}
                  onClick={() =>
                    props.onChangeDirection(TrainDirection.OUTBOUND)
                  }
                >
                  {t(Keys.WORKFLOW.Train.trainDirection.oneway)}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          {props.direction !== TrainDirection.ROUNDTRIP && (
            <Row className="mb-3">
              <Col>
                <RailFlyDirectionField
                  value={props.direction}
                  onChange={props.onChangeDirection}
                ></RailFlyDirectionField>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {props.direction !== TrainDirection.INBOUND && (
        <>
          <Row>
            <Col>
              <strong>
                {t(
                  Keys.WORKFLOW.Train.trainConnection.departureArrival
                    .outwartJourney
                )}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="5">
              <Row>
                <Col>
                  {t(Keys.WORKFLOW.Train.trainConnection.departureArrival.from)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <AutosuggestWrapper
                    onChange={props.onChangeDepartingStationCode}
                    preSelectedValue={props.departingStationCode}
                    values={trainConnections}
                    placeholder={t(
                      Keys.WORKFLOW.Train.trainConnection.departureArrival
                        .station
                    )}
                    id="1"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="4">
              <Row>
                <Col>
                  {t(Keys.WORKFLOW.Train.trainConnection.departureArrival.to)}
                </Col>
              </Row>
              <Row>
                <Col className="mt-1">
                  {/* <strong>{props.destination}</strong> */}
                  <AutosuggestWrapper
                    onChange={props.onChangeDestinationCode}
                    preSelectedValue={props.destinationLabel}
                    values={flightConnections}
                    placeholder={t(
                      Keys.WORKFLOW.Train.trainConnection.departureArrival.airportstation
                    )}
                    id="2"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="3">
              <Row>
                <Col className={styles.noPadding}>
                  {t(
                    Keys.WORKFLOW.Train.trainConnection.departureArrival.class
                  )}
                </Col>
              </Row>
              <Row>
                <Col className={styles.noPadding}>
                  <RailFlyClassField
                    value={props.departingClassCode}
                    onChange={props.onChangeDepartingClassCode}
                  ></RailFlyClassField>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {/* return */}
      {props.direction !== TrainDirection.OUTBOUND && (
        <>
          <Row className="mt-2">
            <Col>
              <strong>
                {t(
                  Keys.WORKFLOW.Train.trainConnection.departureArrival
                    .returnJourney
                )}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="5">
              <Row>
                <Col>
                  {t(Keys.WORKFLOW.Train.trainConnection.departureArrival.from)}
                </Col>
              </Row>
              <Row>
                <Col className="text-left my-auto">
                  {/* <strong>{props.origin}</strong> */}
                  {/* <strong>{props.destinationLabel}</strong> */}
                  <AutosuggestWrapper
                    onChange={props.onChangeReturningDepartureCode}
                    preSelectedValue={props.returningDeparture}
                    values={flightConnections}
                    placeholder={t(
                      Keys.WORKFLOW.Train.trainConnection.departureArrival.airportstation
                    )}
                    id="3"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="4">
              <Row>
                <Col>
                  {t(Keys.WORKFLOW.Train.trainConnection.departureArrival.to)}
                </Col>
              </Row>
              <Row>
                <Col>
                  {props.direction !== TrainDirection.INBOUND && (
                    <AutosuggestWrapper
                      onChange={props.onChangeReturningStationCode}
                      preSelectedValue={props.returningStationCode}
                      values={trainConnections}
                      placeholder={t(
                        Keys.WORKFLOW.Train.trainConnection.departureArrival
                          .station
                      )}
                      id="4"
                    />
                  )}
                  {props.direction === TrainDirection.INBOUND && (
                    <AutosuggestWrapper
                      onChange={props.onChangeReturningStationCode}
                      preSelectedValue={props.returningStationCode}
                      values={trainConnections}
                      placeholder={t(
                        Keys.WORKFLOW.Train.trainConnection.departureArrival
                          .station
                      )}
                      id="5"
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="3">
              <Row>
                <Col className={styles.noPadding}>
                  {t(
                    Keys.WORKFLOW.Train.trainConnection.departureArrival.class
                  )}
                </Col>
              </Row>
              <Row>
                {/* <Col className={css(styles.noPadding, "text-left my-auto")}>
                  {!!props.departingClassCode &&
                    props.departingClassCode >= 0 &&
                    props.departingClassCode <= 2 && (
                      <strong>
                        {trainClasses[props.departingClassCode - 1]}
                      </strong>
                    )}
                </Col> */}
                <Col className={styles.noPadding}>
                  <RailFlyClassField
                    value={props.departingClassCode}
                    onChange={props.onChangeDepartingClassCode}
                  ></RailFlyClassField>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
