import { action, computed, observable, runInAction, toJS } from "mobx";
import SelectFieldOption from "Models/SelectFieldOption";
import { AddonTrain } from "Models/UI/AddonTrain";
import { BookingItemGroup } from "Models/UI/BookingItemGroup";
import { createContext } from "react";
import workflow from "State/WorkflowService";
import { TrainBundle, TrainConnectionItem, TrainDirection } from "../../../Models/TravelSeller/Models/TS_AddonTrain";


export class RailFlyState {
  @observable ready: boolean = false;
  @observable apiErrorMessage: string | null = null;
  @observable showValidation: boolean = false;
  @observable itemErrorMessages: { [trainId: string]: string } = {};
  @observable showSelectionErrorMessages: boolean = false;

  @observable trainStations: SelectFieldOption[];
  @observable flightStations: SelectFieldOption[];
  
  @observable _selectedTrain: AddonTrain | null = null;

  @observable direction: TrainDirection = TrainDirection.ROUNDTRIP;

  @observable bundles: TrainBundle[];
  @observable selectedBundle: TrainBundle;

  @observable departingStationCode: string = "";
  @observable departingClassCode: number = 0;
  @observable departingTrain: TrainConnectionItem;
  @observable departingSearchHour: number;
 
  @observable destinationCode: string = "";
  @observable returningDepartureCode: string = "";

  @observable returningStationCode: string = "";
  @observable returningClassCode: number = 0;
  @observable returningTrain: TrainConnectionItem;
  @observable returningSearchHour: number;

  @action setTrainStations(stations: SelectFieldOption[]) {
    this.trainStations = stations;
  }

  @action setFlightStations(stations: SelectFieldOption[]) {
    this.flightStations = stations;
  }


  @action setTrain(train: AddonTrain) {
    // this._selectedTrain = Object.assign({}, train);
    this._selectedTrain = train;
    this.direction = train.dir;
    this.bundles = train.bundles;
    this.selectedBundle = train.bundles && (train.bundles.find(bundle => bundle.id === train.trainBundleSelected) || train.bundles[0]);
    this.departingStationCode = train.departingSelection.stationCode || "";
    this.departingClassCode = train.departingSelection.classCode || 0;

    this.returningStationCode = train.returningSelection.stationCode || "";
    this.returningClassCode = train.returningSelection.classCode || 0;
  }

  getTrain(): AddonTrain | null {
    // const train = Object.assign({}, this._selectedTrain);
    const train = this._selectedTrain;
    if (train) {
      train.dir = this.direction;
      train.bundles = this.bundles;
      train.trainBundleSelected = this.selectedBundle ? this.selectedBundle.id: '';
      train.departingSelection.stationCode = this.departingStationCode;
      train.departingSelection.classCode = this.departingClassCode;
      train.returningSelection.stationCode = this.returningStationCode;
      train.returningSelection.classCode = this.returningClassCode;
    }
    return train;
  }

  /**
   * Departing Part
   */

  @computed get departingTrains(): TrainConnectionItem[] {
    if (this.selectedTrain) {
      if (this.selectedTrain.departingTrains) {
        return this.selectedTrain.departingTrains;
      }
    }
    return [];
  }

  @action setDepartingStationCode(value: string) {
    this.departingStationCode = value;
  }

  @action setDepartingClassCode(value: number) {
    this.departingClassCode = value;
  }

  @action setDepartingTrain(value: TrainConnectionItem) {
    this.departingTrain = value;
  }

  @action setDepartingSearchHour(value: number) {
    this.departingSearchHour = value;
  }

  @computed get departingStationCodeLabel() {
    if (
      this.departingStationCode &&
      !!this.trainStations &&
      this.trainStations.length > 0
    ) {
      const station = this.trainStations.find(
        station => station.value === this.departingStationCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }

  /**
   * Returning Part
   */

  @action setReturningStationCode(value: string) {
    this.returningStationCode = value;
  }

  @action setReturningClassCode(value: number) {
    this.returningClassCode = value;
  }

  @action setReturningTrain(value: TrainConnectionItem) {
    this.returningTrain = value;
  }

  @action setReturningSearchHour(value: number) {
    this.returningSearchHour = value;
  }

  @action setSelectedBundle(value: TrainBundle) {
    this.selectedBundle = value;
  }

  @computed get returningTrains(): TrainConnectionItem[] {
    if (this.selectedTrain) {
      if (this.selectedTrain.returningTrains) {
        return this.selectedTrain.returningTrains;
      }
    }
    return [];
  }

  @computed get returningStationCodeLabel() {
    if (
      this.returningStationCode &&
      this.trainStations &&
      this.trainStations.length > 0
    ) {
      const station = this.trainStations.find(
        station => station.value === this.returningStationCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }

  /**
   * Validation
   */

  @action updateShowValidation(b: boolean) {
    this.showValidation = b;
  }

  @action validate() {
    this.itemErrorMessages = {};
    if (!!this._selectedTrain) {
      this.validateItem(this._selectedTrain);
    }
  }

  @action validateSelectedItems() {
    this.itemErrorMessages = {};
    if (!!this._selectedTrain) {
      if (
        !!this.fly && this._selectedTrain.id === this.fly.id
      ) {
        this.validateItem(this._selectedTrain);
      }
    } else {
      if (!!this.fly) {
        this.showSelectionErrorMessages = true;
      }
    }
  }

  @action validateItem(item: AddonTrain) {
    let error: string = "";
    if (!!item) {
      if (!item.departingSelection.hasStepOne) {
        error = "error"; //TODO maybe different errors in future
      }
    }
    runInAction(() => {
      this.updateErrorMessages(error, item);
    });
  }

  @action updateErrorMessages(error: string, item: AddonTrain) {
    if (Object.keys(error).length === 0) {
      if (this.itemErrorMessages.hasOwnProperty(item.id)) {
        delete this.itemErrorMessages[item.id];
      }
    } else {
      this.itemErrorMessages[item.id] = error;
    }
  }

  @action removeItemError(item: AddonTrain) {
    if (this.itemErrorMessages.hasOwnProperty(item.id)) {
      delete this.itemErrorMessages[item.id];
    }
  }
  @action removeAllErrors() {
    this.itemErrorMessages = {};
    this.showSelectionErrorMessages = false;
  }

  @computed get hasItemErrors() {
    return Object.keys(this.itemErrorMessages).length > 0;
  }

  @computed get firstErrorId() {
    return Object.keys(this.itemErrorMessages)[0];
  }

  /**
   * Miscellenous
   */

  // @computed getTotalPrice(train: AddonTrain) {
  //   if (!!this._selectedTrain && train.id === this._selectedTrain.id) {
  //     return this._selectedTrain.totalPrice;
  //   }
  //   return 0;
  // }

  @computed get selectedTrain() {
    return this._selectedTrain;
  }

  get departureDate() {
    if (workflow.booking && workflow.booking.travelStart != null) {
      return workflow.booking.travelStart;
    }
    return "";
  }

  get arrivalDate() {
    if (workflow.booking && workflow.booking.travelEnd != null) {
      return workflow.booking.travelEnd;
    }
    return "";
  }

  @action setReturningDepartureCode(value: string) {
    console.log("returningDepartureCode: ", value);
    
    this.returningDepartureCode = value;
  }

  @computed get returningDeparture() {
    return this.returningDepartureCode;
  }

  @computed get returningDepartureLabel() {
    if (
      this.returningDepartureCode &&
      !!this.flightStations &&
      this.flightStations.length > 0
    ) {
      const station = this.flightStations.find(
        station => station.value === this.returningDepartureCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }

  @action setDestinationCode(value: string) {
    this.destinationCode = value;
  }
  

  @computed get destination() {
    if (workflow.booking && workflow.booking.ritDestination != null) {
      return workflow.booking.ritDestination.name || this.destinationCode;
    }
    return this.destinationCode;
  }
  @computed get destinationLabel() {
    if (
      this.destinationCode &&
      !!this.trainStations &&
      this.trainStations.length > 0
    ) {
      const station = this.trainStations.find(
        station => station.value === this.destinationCode
      );
      if (station) {
        return station.label;
      }
    }
    return "";
  }
  get origin() {
    if (workflow.booking && workflow.booking.ritOrigin != null) {
      return workflow.booking.ritOrigin.name || "";
    }
    return "";
  }

  @action updateApiErrorMessage(msg: string | null) {
    this.apiErrorMessage = msg;
  }

  @computed get itemGroup(): BookingItemGroup | null {
    const groups = workflow.stepItems.filter(
      g => g.id === "api-u-no-give-id-for-train-group"
    );
    if (groups.length === 1) {
      return groups[0];
    }
    return null;
  }

  @action readyNow() {
    this.ready = true;
  }

  @computed get selectedItems() {
    return workflow.selectedItems.filter((i: any) => {
      return i instanceof AddonTrain;
    });
  }

  @computed get hasStepItems() {
    return workflow.allStepIds.length > 0;
  }

  @computed get fly() {
    return this.getItemById("132") as AddonTrain;
  }

  @computed get none() {
    return this.getItemById("122") as AddonTrain;
  }

  @computed get isFirstStep() : boolean {
    return workflow.excludedSteps.hasOwnProperty( 'pep-participants' );
}

  @action setDirection(value: TrainDirection) {
    this.direction = value;
  }

  private getItemById(id: string) {
    const g = toJS(this.itemGroup);

    if (!g) {
      return null;
    }

    const items = g.items.filter(i => i.id === id);

    if (items.length === 1) {
      return items[0];
    }

    return null;
  }
}

export const railFlyState = createContext(new RailFlyState());
