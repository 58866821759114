import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import pepxpressStyles from './CartMainItem-pepxpress.module.scss';
import pepxciteStyles from './CartMainItem-pepxcite.module.scss';

import { Hotel } from 'Models/UI/Hotel';
import { Workflow, WorkflowService } from 'State/WorkflowService';
import Price from './Price';
import { Flight } from '../../Models/UI/Flight';
import { FaInfoCircle } from 'react-icons/fa';
import { Keys } from 'Translation/Setup';
import i18next from 'i18next';
import { Room } from '../../Models/UI/Room';
import { ItemType } from '../../Models/UI/ItemType';
import { withTheme } from '../../Context/withTheme';
import { ThemeProps } from '../../Context/AppContext';

interface Props {
  workflow?: WorkflowService;
}
const Key = Keys.COMPONENTS.Cart.Item;
@inject('workflow')
@observer
class CartMainItem extends React.Component<Props & ThemeProps, {}> {
  getPosition(
    pos: { left: number; top: number },
    _currentEvent: any,
    _currentTarget: any,
    node: any,
    _desiredPlace: any,
    effet: any,
    _offset: any
  ) {
    const d = document.documentElement;
    pos.left = Math.min(d.clientWidth - node.clientWidth, pos.left);
    pos.top = Math.min(d.clientHeight - node.clientHeight, pos.top);
    pos.left = Math.max(0, pos.left);
    pos.top = Math.max(0, pos.top);
    return { top: pos.top, left: pos.left };
  }

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    let hotelEl = null;
    let flightEl = null;
    if (this.hotel && this.props.workflow!.workflowName !== Workflow.FLIGHT) {
      hotelEl = (
        <div className={styles.item}>
          <h5>{this.hotel.title}</h5>

          {this.hotel.description && <div>{this.hotel.description}</div>}

          <div className={styles.dateAndPrice}>
            <div className={styles.date}>
              {this.hotel.dateFrom} - {this.hotel.dateUntil}
            </div>
            <div className={styles.price}>
              <Price amount={this.hotel.totalPrice} />
            </div>
          </div>
          <div>
            {this.props.workflow!.workflowName === Workflow.HOTEL_FLIGHT &&
              this.earlyBird.length > 0 && (
                <div className={styles.earlyBird}>
                  {this.earlyBird.map((earlyBird, index) => (
                    <div key={`earlybird-${index}-${earlyBird.id || earlyBird.title}`} className={styles.earlyBirdItem}>
                      <div className={styles.name}>{earlyBird.title}</div>
                      <div className={styles.price}>
                        <Price amount={earlyBird.totalPrice} />
                      </div>
                    </div>
                    ))}
                </div>
              )}
            {this.props.workflow!.workflowName === Workflow.HOTEL_FLIGHT && this.rooms.length > 0 && (
              <div className={styles.room}>
                {this.rooms.map((room, index) => {
                    const count = room.count && room.count > 1 ? room.count + 'x ' : '';
                    return (
                      <div 
                        key={`room-${index}-${room.id || room.title}`} 
                        className={styles.name}
                      >
                        {count + room.title}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      );
    }
    if (
      this.flights &&
      this.flights.length > 0 &&
      (this.props.workflow!.workflowName === Workflow.FLIGHT ||
        this.props.workflow!.workflowName === Workflow.HOTEL_FLIGHT)
    ) {
      if (this.props.workflow!.workflowName === Workflow.FLIGHT) {
        hotelEl = (
          <div className={styles.fakeFlightItem}>
            <h5>{this.hotel.title}</h5>

            {this.hotel.description && <div>{this.hotel.description}</div>}
          </div>
        );
      }
      flightEl = (
        <>
          {this.props.workflow!.workflowName !== Workflow.HOTEL_FLIGHT && (
            <div className={styles.item}>
              <div className={styles.flightDescription}>{this.flights[0].description}</div>
            </div>
          )}
          {this.renderFlights(this.flights, styles)}
        </>
      );
    }
    return (
      <>
        {hotelEl}
        {flightEl}
      </>
    );
  }

  renderFlights(flights: Flight[], styles: { [key: string]: string }) {
    return (
      <>
        {flights.map((flight, index) => {
          return (
            <div className={styles.flihgtItem} key={'flight_' + index}>
              <div className={'d-flex justify-content-between'}>
                <h5>
                  {flight.title}: {flight.departureAirport + ' - ' + flight.destinationAirport}
                </h5>
                <div>
                  {flight.descriptionList && (
                    <>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        data-tip
                        data-for={'tipFlight_' + index}
                      />
                      <ReactTooltip id={'tipFlight_' + index} overridePosition={this.getPosition}>
                        <>
                          {flight.descriptionList.length > 0 &&
                            flight.descriptionList.map((desc, i) => (
                              <div
                                key={'flightDescription' + index + '_' + i}
                                dangerouslySetInnerHTML={{
                                  __html: desc
                                }}
                              />
                            ))}
                        </>
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </div>

              {!flight.flightStopovers && (
                <>
                  <div>
                    <span>{flight.dateFrom + ' ' + i18next.t(Key.at) + ' ' + flight.timeFrom}</span>
                    <span>{' - '}</span>
                    <span>
                      {flight.dateUntil + ' ' + i18next.t(Key.at) + ' ' + flight.timeUntil}
                    </span>
                  </div>
                </>
              )}
              {flight.flightStopovers &&
                flight.flightStopovers.map((so, i) => {
                  const [fromTo, dateAndTime] = so;
                  return (
                    <div key={'stopover' + i}>
                      <div> {fromTo} </div>
                      <div> {dateAndTime} </div>
                    </div>
                  );
                })}
              <div>{flight.flightNumber + ', ' + flight.cabinClass}</div>

              <div className={styles.flihgtDateAndPrice}>
                <div className={styles.date}></div>
                <div className={styles.price}>
                  <Price amount={flight.totalPrice} />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  @computed
  get hotel(): Hotel {
    const items = this.props.workflow!.selectedItems;
    try {
      return items.filter(i => i instanceof Hotel)[0] as Hotel;
    } catch (e) {
      return new Hotel();
    }
  }

  @computed
  get rooms(): Room[] {
    const items = this.props.workflow!.selectedItems;
    try {
      return items.filter(i => i.type === ItemType.ROOM);
    } catch (e) {
      return [];
    }
  }

  @computed
  get earlyBird() {
    const items = this.props.workflow!.selectedItems;
    try {
      return items.filter(i => i.type === ItemType.EARLY_BIRD);
    } catch (e) {
      return [];
    }
  }

  @computed
  get flights(): Flight[] | null {
    const items = this.props.workflow!.selectedItems;
    try {
      return items.filter(i => i instanceof Flight).map(ele => ele as Flight);
    } catch (e) {
      return [];
    }
  }

  // @computed
  // get flightNotes() : ReactNode | null {
  //     const items = this.props.workflow!.selectedItems;
  //     try {
  //         return items.filter( i => i instanceof Note ).map((ele: Note, i) => {
  //             return <div key={'flightNote' + i} dangerouslySetInnerHTML={{
  //                 __html: ele.title
  //               }}></div>
  //         });
  //     } catch ( e ) {
  //         return null;
  //     }
  // }
}
export default withTheme(CartMainItem);
