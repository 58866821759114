/* This file was generated by running "node scripts/generateTranslationTypes.js". */
/* Created: 2024-04-12 10:29:41 */
/* Created: 2024-04-12 10:29:41 */

export const Keys = {
  COMPONENTS: {
    SelectField: {
      child: 'COMPONENTS.SelectField.child',
      salutation: 'COMPONENTS.SelectField.salutation',
      men: 'COMPONENTS.SelectField.men',
      women: 'COMPONENTS.SelectField.women'
    },
    TimeSelect: { select: 'COMPONENTS.TimeSelect.select' },
    DateSelect: { plsSelect: 'COMPONENTS.DateSelect.plsSelect' },
    NationalitySelect: { plsSelect: 'COMPONENTS.NationalitySelect.plsSelect' },
    DonationSelect: { plsSelect: 'COMPONENTS.DonationSelect.plsSelect' },
    ErrorDisplay: {
      RouteNotFoundError: {
        h1: 'COMPONENTS.ErrorDisplay.RouteNotFoundError.h1',
        h2: 'COMPONENTS.ErrorDisplay.RouteNotFoundError.h2'
      },
      EnpointError: {
        h1: 'COMPONENTS.ErrorDisplay.EnpointError.h1',
        h2: 'COMPONENTS.ErrorDisplay.EnpointError.h2'
      },
      ExpectedError: {
        h1: 'COMPONENTS.ErrorDisplay.ExpectedError.h1',
        h2: 'COMPONENTS.ErrorDisplay.ExpectedError.h2'
      },
      DefaultError: {
        h1: 'COMPONENTS.ErrorDisplay.DefaultError.h1',
        h2: 'COMPONENTS.ErrorDisplay.DefaultError.h2'
      }
    },
    Router: {
      headline: 'COMPONENTS.Router.headline',
      explanation: 'COMPONENTS.Router.explanation',
      screenshot: 'COMPONENTS.Router.screenshot',
      inputLabel: 'COMPONENTS.Router.inputLabel',
      callToAction: 'COMPONENTS.Router.callToAction',
      byebye: 'COMPONENTS.Router.byebye'
    },
    Nav: {
      next: 'COMPONENTS.Nav.next',
      prev: 'COMPONENTS.Nav.prev',
      close: 'COMPONENTS.Nav.close'
    },
    Mobile: {
      Nav: {
        add: 'COMPONENTS.Mobile.Nav.add',
        next: 'COMPONENTS.Mobile.Nav.next'
      }
    },
    ContactData: {
      payment: { headline: 'COMPONENTS.ContactData.payment.headline' },
      contact: {
        headline: 'COMPONENTS.ContactData.contact.headline',
        phone: 'COMPONENTS.ContactData.contact.phone',
        mobile: 'COMPONENTS.ContactData.contact.mobile',
        email: 'COMPONENTS.ContactData.contact.email'
      }
    },
    Cart: {
      included: 'COMPONENTS.Cart.included',
      Item: {
        at: 'COMPONENTS.Cart.Item.at',
        total: 'COMPONENTS.Cart.Item.total',
        paymentMethod: 'COMPONENTS.Cart.Item.paymentMethod',
        deposit: 'COMPONENTS.Cart.Item.deposit',
        day: 'COMPONENTS.Cart.Item.day',
        date: 'COMPONENTS.Cart.Item.date',
        time: 'COMPONENTS.Cart.Item.time',
        select: 'COMPONENTS.Cart.Item.select',
        enter: 'COMPONENTS.Cart.Item.enter',
        outwardTravel: 'COMPONENTS.Cart.Item.outwardTravel',
        returnTravel: 'COMPONENTS.Cart.Item.returnTravel',
        flightNumber: 'COMPONENTS.Cart.Item.flightNumber',
        train: 'COMPONENTS.Cart.Item.train'
      },
      requestBooking: 'COMPONENTS.Cart.requestBooking',
      bindingBooking: 'COMPONENTS.Cart.bindingBooking'
    }
  },
  COMMON: {
    Languages: { de: 'COMMON.Languages.de', en: 'COMMON.Languages.en' },
    Steps: {
      'pep-entrance': 'COMMON.Steps.pep-entrance',
      'pep-additional-services': 'COMMON.Steps.pep-additional-services',
      'pep-train': 'COMMON.Steps.pep-train',
      'pep-participants': 'COMMON.Steps.pep-participants',
      'pep-insurance': 'COMMON.Steps.pep-insurance',
      'pep-payment': 'COMMON.Steps.pep-payment',
      'pep-overview': 'COMMON.Steps.pep-overview',
      'pep-summary': 'COMMON.Steps.pep-summary',
      'pep-upsell': 'COMMON.Steps.pep-upsell',
      'pep-rail-fly': 'COMMON.Steps.pep-rail-fly'
    },
    Validation: {
      salutation: { empty: 'COMMON.Validation.salutation.empty' },
      surname: {
        empty: 'COMMON.Validation.surname.empty',
        onlyLetters: 'COMMON.Validation.surname.onlyLetters'
      },
      firstname: {
        empty: 'COMMON.Validation.firstname.empty',
        onlyLetters: 'COMMON.Validation.firstname.onlyLetters'
      },
      country: { empty: 'COMMON.Validation.country.empty' },
      nationality: { empty: 'COMMON.Validation.nationality.empty' },
      day: { empty: 'COMMON.Validation.day.empty' },
      month: { empty: 'COMMON.Validation.month.empty' },
      year: { empty: 'COMMON.Validation.year.empty' },
      date: { invalid: 'COMMON.Validation.date.invalid' },
      street: { empty: 'COMMON.Validation.street.empty' },
      organisation: { empty: 'COMMON.Validation.organisation.empty' },
      streetNumber: { empty: 'COMMON.Validation.streetNumber.empty' },
      postcode: { empty: 'COMMON.Validation.postcode.empty' },
      city: { empty: 'COMMON.Validation.city.empty' },
      email: {
        empty: 'COMMON.Validation.email.empty',
        invalid: 'COMMON.Validation.email.invalid'
      },
      mobile: {
        empty: 'COMMON.Validation.mobile.empty',
        invalid: 'COMMON.Validation.mobile.invalid'
      },
      phone: {
        empty: 'COMMON.Validation.phone.empty',
        invalid: 'COMMON.Validation.phone.invalid'
      },
      passportNumber: {
        empty: 'COMMON.Validation.passportNumber.empty',
        invalid: 'COMMON.Validation.passportNumber.invalid'
      },
      passportValidThru: {
        empty: 'COMMON.Validation.passportValidThru.empty',
        invalid: 'COMMON.Validation.passportValidThru.invalid'
      }
    }
  },
  WORKFLOW: {
    Entry: { loading: 'WORKFLOW.Entry.loading' },
    AdditionalServices: {
      headline: 'WORKFLOW.AdditionalServices.headline',
      mandatoryNote: 'WORKFLOW.AdditionalServices.mandatoryNote',
      globalFormError: 'WORKFLOW.AdditionalServices.globalFormError',
      apiError: 'WORKFLOW.AdditionalServices.apiError',
      itemError: 'WORKFLOW.AdditionalServices.itemError',
      singleSelectError: 'WORKFLOW.AdditionalServices.singleSelectError',
      multiSelectMandatoryError: 'WORKFLOW.AdditionalServices.multiSelectMandatoryError',
      multiSelectError: 'WORKFLOW.AdditionalServices.multiSelectError',
      readMore: 'WORKFLOW.AdditionalServices.readMore',
      readLess: 'WORKFLOW.AdditionalServices.readLess',
      from: 'WORKFLOW.AdditionalServices.from',
      musicalNotAvailable: 'WORKFLOW.AdditionalServices.musicalNotAvailable',
      AddonBus: {
        defaultOption: 'WORKFLOW.AdditionalServices.AddonBus.defaultOption',
        addText: 'WORKFLOW.AdditionalServices.AddonBus.addText'
      },
      AddonEvent: {
        selectedDateIsEmpty: 'WORKFLOW.AdditionalServices.AddonEvent.selectedDateIsEmpty',
        selectedVariantIsEmpty: 'WORKFLOW.AdditionalServices.AddonEvent.selectedVariantIsEmpty',
        defaultOption: 'WORKFLOW.AdditionalServices.AddonEvent.defaultOption',
        day: 'WORKFLOW.AdditionalServices.AddonEvent.day',
        time: 'WORKFLOW.AdditionalServices.AddonEvent.time',
        chooseSeats: 'WORKFLOW.AdditionalServices.AddonEvent.chooseSeats',
        applySelection: 'WORKFLOW.AdditionalServices.AddonEvent.applySelection',
        selectedShowWrongNrOfParticipants: 'WORKFLOW.AdditionalServices.AddonEvent.selectedShowWrongNrOfParticipants',
        cancel: 'WORKFLOW.AdditionalServices.AddonEvent.cancel'
      },
      AddonTransfer: {
        inboundNrIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.inboundNrIsEmpty',
        inboundDateIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.inboundDateIsEmpty',
        inboundTimeIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.inboundTimeIsEmpty',
        outboundNrIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.outboundNrIsEmpty',
        outboundDateIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.outboundDateIsEmpty',
        outboundTimeIsEmpty: 'WORKFLOW.AdditionalServices.AddonTransfer.outboundTimeIsEmpty',
        outbound: 'WORKFLOW.AdditionalServices.AddonTransfer.outbound',
        inbound: 'WORKFLOW.AdditionalServices.AddonTransfer.inbound',
        flightNo: 'WORKFLOW.AdditionalServices.AddonTransfer.flightNo',
        flightNoPlaceholder: 'WORKFLOW.AdditionalServices.AddonTransfer.flightNoPlaceholder',
        date: 'WORKFLOW.AdditionalServices.AddonTransfer.date',
        time: 'WORKFLOW.AdditionalServices.AddonTransfer.time'
      },
      AddonFlexPep: {
        addToCart: 'WORKFLOW.AdditionalServices.AddonFlexPep.addToCart'
      }
    },
    Train: {
      headline: 'WORKFLOW.Train.headline',
      agencyNote: 'WORKFLOW.Train.agencyNote',
      selectionError: 'WORKFLOW.Train.selectionError',
      globalFormError: 'WORKFLOW.Train.globalFormError',
      itemError: 'WORKFLOW.Train.itemError',
      apiError: 'WORKFLOW.Train.apiError',
      readMore: 'WORKFLOW.Train.readMore',
      readLess: 'WORKFLOW.Train.readLess',
      intro: {
        title: 'WORKFLOW.Train.intro.title',
        text: 'WORKFLOW.Train.intro.text'
      },
      train: {
        description: 'WORKFLOW.Train.train.description',
        label: 'WORKFLOW.Train.train.label'
      },
      none: {
        description: 'WORKFLOW.Train.none.description',
        label: 'WORKFLOW.Train.none.label'
      },
      late: {
        description: 'WORKFLOW.Train.late.description',
        label: 'WORKFLOW.Train.late.label'
      },
      upsell: {
        spar1: 'WORKFLOW.Train.upsell.spar1',
        spar2: 'WORKFLOW.Train.upsell.spar2',
        flex1: 'WORKFLOW.Train.upsell.flex1',
        flex2: 'WORKFLOW.Train.upsell.flex2',
        overwrite: 'WORKFLOW.Train.upsell.overwrite',
        cancel: 'WORKFLOW.Train.upsell.cancel'
      },
      trainConnection: {
        departureArrival: {
          headline: 'WORKFLOW.Train.trainConnection.departureArrival.headline',
          outwartJourney: 'WORKFLOW.Train.trainConnection.departureArrival.outwartJourney',
          arrivalFrom: 'WORKFLOW.Train.trainConnection.departureArrival.arrivalFrom',
          returnJourney: 'WORKFLOW.Train.trainConnection.departureArrival.returnJourney',
          departureTo: 'WORKFLOW.Train.trainConnection.departureArrival.departureTo',
          to: 'WORKFLOW.Train.trainConnection.departureArrival.to',
          from: 'WORKFLOW.Train.trainConnection.departureArrival.from',
          class: 'WORKFLOW.Train.trainConnection.departureArrival.class',
          station: 'WORKFLOW.Train.trainConnection.departureArrival.station',
          airportstation: 'WORKFLOW.Train.trainConnection.departureArrival.airportstation'
        },
        connection: {
          headline: 'WORKFLOW.Train.trainConnection.connection.headline',
          outwartRun: 'WORKFLOW.Train.trainConnection.connection.outwartRun',
          returnRun: 'WORKFLOW.Train.trainConnection.connection.returnRun',
          change: 'WORKFLOW.Train.trainConnection.connection.change',
          change_plural: 'WORKFLOW.Train.trainConnection.connection.change_plural',
          proPerson: 'WORKFLOW.Train.trainConnection.connection.proPerson',
          earlier: 'WORKFLOW.Train.trainConnection.connection.earlier',
          later: 'WORKFLOW.Train.trainConnection.connection.later',
          at: 'WORKFLOW.Train.trainConnection.connection.at',
          noConnection: 'WORKFLOW.Train.trainConnection.connection.noConnection',
          from: 'WORKFLOW.Train.trainConnection.connection.from'
        },
        details: {
          headline: 'WORKFLOW.Train.trainConnection.details.headline',
          cabin: {
            headline: 'WORKFLOW.Train.trainConnection.details.cabin.headline',
            any: 'WORKFLOW.Train.trainConnection.details.cabin.any',
            wideArea: 'WORKFLOW.Train.trainConnection.details.cabin.wideArea',
            wideAreaTable: 'WORKFLOW.Train.trainConnection.details.cabin.wideAreaTable',
            section: 'WORKFLOW.Train.trainConnection.details.cabin.section'
          },
          seatCategory: {
            headline: 'WORKFLOW.Train.trainConnection.details.seatCategory.headline',
            any: 'WORKFLOW.Train.trainConnection.details.seatCategory.any',
            window: 'WORKFLOW.Train.trainConnection.details.seatCategory.window',
            corridor: 'WORKFLOW.Train.trainConnection.details.seatCategory.corridor'
          },
          area: {
            headline: 'WORKFLOW.Train.trainConnection.details.area.headline',
            any: 'WORKFLOW.Train.trainConnection.details.area.any',
            mobilePhone: 'WORKFLOW.Train.trainConnection.details.area.mobilePhone',
            chillOut: 'WORKFLOW.Train.trainConnection.details.area.chillOut'
          }
        }
      },
      trainClasses: {
        first: 'WORKFLOW.Train.trainClasses.first',
        second: 'WORKFLOW.Train.trainClasses.second'
      },
      trainDirection: {
        roundtrip: 'WORKFLOW.Train.trainDirection.roundtrip',
        oneway: 'WORKFLOW.Train.trainDirection.oneway',
        outbound: 'WORKFLOW.Train.trainDirection.outbound',
        incoming: 'WORKFLOW.Train.trainDirection.incoming'
      }
    },
    Insurance: {
      headline: 'WORKFLOW.Insurance.headline',
      angencyNote: 'WORKFLOW.Insurance.angencyNote',
      subscription: {
        headline: 'WORKFLOW.Insurance.subscription.headline',
        url: 'WORKFLOW.Insurance.subscription.url',
        label: 'WORKFLOW.Insurance.subscription.label',
        text1: 'WORKFLOW.Insurance.subscription.text1',
        text2: 'WORKFLOW.Insurance.subscription.text2',
        text3: 'WORKFLOW.Insurance.subscription.text3',
        text4: 'WORKFLOW.Insurance.subscription.text4',
        linkText: 'WORKFLOW.Insurance.subscription.linkText'
      },
      intro: {
        title: 'WORKFLOW.Insurance.intro.title',
        text: 'WORKFLOW.Insurance.intro.text'
      },
      large: {
        topSeller: 'WORKFLOW.Insurance.large.topSeller',
        description: 'WORKFLOW.Insurance.large.description',
        label: 'WORKFLOW.Insurance.large.label',
        subhead: 'WORKFLOW.Insurance.large.subhead'
      },
      medium: {
        description: 'WORKFLOW.Insurance.medium.description',
        label: 'WORKFLOW.Insurance.medium.label'
      },
      nope: {
        headline: 'WORKFLOW.Insurance.nope.headline',
        label: 'WORKFLOW.Insurance.nope.label'
      }
    },
    Participants: {
      headline: 'WORKFLOW.Participants.headline',
      mainCustomer: 'WORKFLOW.Participants.mainCustomer',
      travellers: 'WORKFLOW.Participants.travellers',
      mandatoryNote: 'WORKFLOW.Participants.mandatoryNote',
      formMainError: 'WORKFLOW.Participants.formMainError',
      apiError: 'WORKFLOW.Participants.apiError',
      Forms: {
        BirthdayFields: {
          day: 'WORKFLOW.Participants.Forms.BirthdayFields.day',
          month: 'WORKFLOW.Participants.Forms.BirthdayFields.month',
          year: 'WORKFLOW.Participants.Forms.BirthdayFields.year'
        },
        PassengerForm: {
          title: 'WORKFLOW.Participants.Forms.PassengerForm.title',
          firstname: 'WORKFLOW.Participants.Forms.PassengerForm.firstname',
          surname: 'WORKFLOW.Participants.Forms.PassengerForm.surname',
          passportNumber: 'WORKFLOW.Participants.Forms.PassengerForm.passportNumber',
          passportValidThru: 'WORKFLOW.Participants.Forms.PassengerForm.passportValidThru'
        },
        BusinessPartnerForm: {
          name: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.name',
          title: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.title',
          firstname: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.firstname',
          surname: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.surname',
          employer: {
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.employer.required'
          },
          organisation: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.organisation',
          street: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.street.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.street.required'
          },
          streetNumber: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.streetNumber',
          town: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.town.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.town.required'
          },
          postcode: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.postcode',
          city: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.city',
          country: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.country.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.country.required'
          },
          nationality: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.nationality.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.nationality.required'
          },
          phone: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.phone',
          mobile: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.required',
            emergency: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.emergency'
          },
          email: {
            label: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.email.label',
            required: 'WORKFLOW.Participants.Forms.BusinessPartnerForm.email.required'
          }
        }
      }
    },
    Payment: {
      headline: 'WORKFLOW.Payment.headline',
      mandatoryNote: 'WORKFLOW.Payment.mandatoryNote',
      formMainError: 'WORKFLOW.Payment.formMainError',
      apiError: 'WORKFLOW.Payment.apiError',
      Forms: {
        IBANPaymentForm: {
          paymentAccountHolder: 'WORKFLOW.Payment.Forms.IBANPaymentForm.paymentAccountHolder',
          paymentIban: 'WORKFLOW.Payment.Forms.IBANPaymentForm.paymentIban',
          paymentBic: 'WORKFLOW.Payment.Forms.IBANPaymentForm.paymentBic',
          paymentAccept: 'WORKFLOW.Payment.Forms.IBANPaymentForm.paymentAccept'
        }
      },
      donation: {
        headline: 'WORKFLOW.Payment.donation.headline',
        intro: 'WORKFLOW.Payment.donation.intro',
        detail1: 'WORKFLOW.Payment.donation.detail1',
        detail2: 'WORKFLOW.Payment.donation.detail2',
        detail3: 'WORKFLOW.Payment.donation.detail3',
        link: 'WORKFLOW.Payment.donation.link',
        alt: 'WORKFLOW.Payment.donation.alt',
        label: 'WORKFLOW.Payment.donation.label'
      },
      bonuscard: {
        headline: 'WORKFLOW.Payment.bonuscard.headline',
        savings: 'WORKFLOW.Payment.bonuscard.savings',
        detail1: 'WORKFLOW.Payment.bonuscard.detail1',
        detail2: 'WORKFLOW.Payment.bonuscard.detail2',
        detail3: 'WORKFLOW.Payment.bonuscard.detail3',
        detail4: 'WORKFLOW.Payment.bonuscard.detail4',
        detail5: 'WORKFLOW.Payment.bonuscard.detail5',
        detail6: 'WORKFLOW.Payment.bonuscard.detail6',
        detail7: 'WORKFLOW.Payment.bonuscard.detail7',
        link: 'WORKFLOW.Payment.bonuscard.link',
        alt: 'WORKFLOW.Payment.bonuscard.alt',
        label: 'WORKFLOW.Payment.bonuscard.label'
      },
      coupon: {
        headline: 'WORKFLOW.Payment.coupon.headline',
        wasApplied: 'WORKFLOW.Payment.coupon.wasApplied',
        remove: 'WORKFLOW.Payment.coupon.remove',
        placeholder: 'WORKFLOW.Payment.coupon.placeholder',
        apply: 'WORKFLOW.Payment.coupon.apply',
        total: 'WORKFLOW.Payment.coupon.total',
        deposit: 'WORKFLOW.Payment.coupon.deposit'
      },
      types: {
        sepaNotAvailable: {
          headline: 'WORKFLOW.Payment.types.sepaNotAvailable.headline',
          text: 'WORKFLOW.Payment.types.sepaNotAvailable.text'
        },
        ibanDataNotComplete: {
          alert: 'WORKFLOW.Payment.types.ibanDataNotComplete.alert'
        },
        headline: 'WORKFLOW.Payment.types.headline',
        alert: 'WORKFLOW.Payment.types.alert'
      },
      modal: {
        headline: 'WORKFLOW.Payment.modal.headline',
        body1: 'WORKFLOW.Payment.modal.body1',
        body2: 'WORKFLOW.Payment.modal.body2',
        body3: 'WORKFLOW.Payment.modal.body3',
        body4: 'WORKFLOW.Payment.modal.body4',
        chooseOtherPayment: 'WORKFLOW.Payment.modal.chooseOtherPayment'
      }
    },
    Overview: {
      headline: 'WORKFLOW.Overview.headline',
      includedItems: 'WORKFLOW.Overview.includedItems',
      extraNights: 'WORKFLOW.Overview.extraNights',
      completeBooking: 'WORKFLOW.Overview.completeBooking',
      notesAlert: 'WORKFLOW.Overview.notesAlert',
      legalFirst: 'WORKFLOW.Overview.legalFirst',
      legalSecond: 'WORKFLOW.Overview.legalSecond',
      legal1: 'WORKFLOW.Overview.legal1',
      legal2: 'WORKFLOW.Overview.legal2',
      legal3: 'WORKFLOW.Overview.legal3',
      newsletter: 'WORKFLOW.Overview.newsletter',
      bookNow: 'WORKFLOW.Overview.bookNow',
      Participants: 'WORKFLOW.Overview.Participants',
      preparePaymentError: 'WORKFLOW.Overview.preparePaymentError',
      paymentRedirect1: 'WORKFLOW.Overview.paymentRedirect1',
      paymentRedirect2: 'WORKFLOW.Overview.paymentRedirect2',
      paymentErrorUrlParams: 'WORKFLOW.Overview.paymentErrorUrlParams',
      paymentErrorExternalNotSuccessful: 'WORKFLOW.Overview.paymentErrorExternalNotSuccessful',
      paymentErrorApiCheckFailed: 'WORKFLOW.Overview.paymentErrorApiCheckFailed',
      paymentErrorInternalNotSuccessful: 'WORKFLOW.Overview.paymentErrorInternalNotSuccessful',
      paymentErrorApiStoreFailed: 'WORKFLOW.Overview.paymentErrorApiStoreFailed',
      validationModal: {
        confirm: 'WORKFLOW.Overview.validationModal.confirm',
        closeTab: 'WORKFLOW.Overview.validationModal.closeTab'
      }
    },
    Summary: {
      headline: 'WORKFLOW.Summary.headline',
      bookingNo: 'WORKFLOW.Summary.bookingNo',
      bookingStatus: 'WORKFLOW.Summary.bookingStatus',
      labelOk: 'WORKFLOW.Summary.labelOk',
      labelOption: 'WORKFLOW.Summary.labelOption',
      textOk: 'WORKFLOW.Summary.textOk',
      textOption: 'WORKFLOW.Summary.textOption',
      includedItems: 'WORKFLOW.Summary.includedItems',
      extraNights: 'WORKFLOW.Summary.extraNights',
      backToHomepage: 'WORKFLOW.Summary.backToHomepage',
      close: 'WORKFLOW.Summary.close',
      Participants: 'WORKFLOW.Summary.Participants'
    },
    Upsell: {
      headline: 'WORKFLOW.Upsell.headline',
      from: 'WORKFLOW.Upsell.from',
      goToLink: 'WORKFLOW.Upsell.goToLink',
      hotelHeadline: 'WORKFLOW.Upsell.hotelHeadline',
      eventHeadline: 'WORKFLOW.Upsell.eventHeadline',
      flightHeadline: 'WORKFLOW.Upsell.flightHeadline',
      transferHeadline: 'WORKFLOW.Upsell.transferHeadline',
      rentalCarHeadline: 'WORKFLOW.Upsell.rentalCarHeadline',
      parkingHeadline: 'WORKFLOW.Upsell.parkingHeadline',
      coronaHeadline: 'WORKFLOW.Upsell.coronaHeadline',
      close: 'WORKFLOW.Upsell.close',
      benefitsPartner: 'WORKFLOW.Upsell.benefitsPartner',
      subHeadline: 'WORKFLOW.Upsell.subHeadline',
      insurances: 'WORKFLOW.Upsell.insurances',
      pepAusweis: 'WORKFLOW.Upsell.pepAusweis',
      transfer: 'WORKFLOW.Upsell.transfer',
      newsletter: 'WORKFLOW.Upsell.newsletter',
      ski: 'WORKFLOW.Upsell.ski',
      parking: 'WORKFLOW.Upsell.parking',
      events: 'WORKFLOW.Upsell.events',
      rentalCar: 'WORKFLOW.Upsell.rentalCar'
    },
    RailFly: {
      headline: 'WORKFLOW.RailFly.headline',
      railFly: {
        headline: 'WORKFLOW.RailFly.railFly.headline',
        step1: {
          headline: 'WORKFLOW.RailFly.railFly.step1.headline',
          point1: 'WORKFLOW.RailFly.railFly.step1.point1',
          point2: 'WORKFLOW.RailFly.railFly.step1.point2'
        },
        step2: {
          headline: 'WORKFLOW.RailFly.railFly.step2.headline',
          point1: 'WORKFLOW.RailFly.railFly.step2.point1'
        }
      },
      selectionError: 'WORKFLOW.RailFly.selectionError',
      globalFormError: 'WORKFLOW.RailFly.globalFormError',
      itemError: 'WORKFLOW.RailFly.itemError',
      apiError: 'WORKFLOW.RailFly.apiError',
      readMore: 'WORKFLOW.RailFly.readMore',
      readLess: 'WORKFLOW.RailFly.readLess',
      intro: { text: 'WORKFLOW.RailFly.intro.text' },
      fly: {
        description: 'WORKFLOW.RailFly.fly.description',
        label: 'WORKFLOW.RailFly.fly.label'
      },
      none: {
        description: 'WORKFLOW.RailFly.none.description',
        label: 'WORKFLOW.RailFly.none.label'
      },
      trainConnection: {
        departureArrival: {
          headline: 'WORKFLOW.RailFly.trainConnection.departureArrival.headline',
          class: 'WORKFLOW.RailFly.trainConnection.departureArrival.class',
          station: 'WORKFLOW.RailFly.trainConnection.departureArrival.station'
        }
      }
    }
  }
};