import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

import Price from 'Components/Workflow/Price';
import { TrainConnectionItem, TrainDirection } from 'Models/TravelSeller/Models/TS_AddonTrain';
import { createUniqueId } from 'Helpers/id';

import { Keys } from 'Translation/Setup';
import styles from '../Train.module.scss';

interface Props {
  id: string;
  direction: TrainDirection;
  departingTrains: TrainConnectionItem[];
  returningTrains: TrainConnectionItem[];
  selectedDepartingTrain: TrainConnectionItem;
  selectedReturningTrain: TrainConnectionItem;
  selectedDepartingSearchHour?: number;
  selectedReturningSearchHour?: number;
  destination: string;
  destinationLabel: string;
  departureDate: string;
  departingStation: string;
  returningDate: string;
  returningStation: string;
  onChangeDepartingTrain: (value: TrainConnectionItem) => void;
  onChangeDepartingSearchHour?: (value: number) => void;
  onChangeReturningTrain: (value: TrainConnectionItem) => void;
  onChangeReturningSearchHour?: (value: number) => void;
}

export const TrainConnection = (props: Props) => {
  const { t } = useTranslation();

  const uID = createUniqueId('trainConnection');

  return (
    <div className={styles.seperator}>
      <h5>{t(Keys.WORKFLOW.Train.trainConnection.connection.headline)}</h5>
      <div className={styles.connections}>
        {props.direction !== TrainDirection.INBOUND && props.departingStation.length > 0 && (
          <div className={styles.connection}>
            <div className={styles.connectionHeader}>
              <strong>
                {t(Keys.WORKFLOW.Train.trainConnection.connection.outwartRun)}{' '}
                {t(Keys.WORKFLOW.Train.trainConnection.connection.at)}
                <span>&nbsp;{props.departureDate}</span>
              </strong>
              <div>
                <span>{props.departingStation}</span>
                <span>&nbsp;-&nbsp;</span>
                <span>{props.destinationLabel}</span>
              </div>
            </div>
            <div className={styles.connectionMenue}>
              {props.selectedDepartingSearchHour && (
                <button
                  className={styles.connectionButton}
                  type="button"
                  disabled={
                    props.selectedDepartingSearchHour <= 0 || props.departingTrains.length === 0
                  }
                  onClick={() => searchDepartingEarlierTrains()}
                >
                  {t(Keys.WORKFLOW.Train.trainConnection.connection.earlier)} &#9650;
                </button>
              )}
              {props.departingTrains.length > 0 && (
                <ul>
                  {props.departingTrains.map((item, i) =>
                    renderItem(
                      'departure',
                      i + 2,
                      'departureConn',
                      item,
                      props.onChangeDepartingTrain,
                      props.selectedDepartingTrain
                    )
                  )}
                </ul>
              )}
              {props.departingTrains.length === 0 && (
                <div>
                  <strong>{t(Keys.WORKFLOW.Train.trainConnection.connection.noConnection)}</strong>
                </div>
              )}
              {props.selectedDepartingSearchHour && (
                <button
                  className={styles.connectionButton}
                  type="button"
                  disabled={
                    props.selectedDepartingSearchHour >= 24 || props.departingTrains.length === 0
                  }
                  onClick={() => searchDepartingLaterTrains()}
                >
                  {t(Keys.WORKFLOW.Train.trainConnection.connection.later)} &#9660;
                </button>
              )}
            </div>
          </div>
        )}

        {/* returning train connection */}

        {props.direction !== TrainDirection.OUTBOUND && props.returningStation.length > 0 && (
          <div className={styles.connection}>
            <div className={styles.connectionHeader}>
              <strong>
                {t(Keys.WORKFLOW.Train.trainConnection.connection.returnRun)}{' '}
                {t(Keys.WORKFLOW.Train.trainConnection.connection.at)}
                <span>&nbsp;{props.returningDate}</span>
              </strong>
              <div>
                <span>{props.destinationLabel}</span>
                <span>&nbsp;-&nbsp;</span>
                <span>{props.returningStation}</span>
              </div>
            </div>
            <div className={styles.connectionMenue}>
              {props.selectedReturningSearchHour && (
                <button
                  className={styles.connectionButton}
                  type="button"
                  disabled={
                    props.selectedReturningSearchHour <= 0 || props.returningTrains.length === 0
                  }
                  onClick={() => searchReturningEarlierTrains()}
                >
                  {t(Keys.WORKFLOW.Train.trainConnection.connection.earlier)} &#9650;
                </button>
              )}
              {props.returningTrains.length > 0 && (
                <ul>
                  {props.returningTrains.map((item, i) =>
                    renderItem(
                      'returning',
                      i + 2,
                      'returningCon',
                      item,
                      props.onChangeReturningTrain,
                      props.selectedReturningTrain
                    )
                  )}
                </ul>
              )}
              {props.returningTrains.length === 0 && (
                <div>
                  <strong>{t(Keys.WORKFLOW.Train.trainConnection.connection.noConnection)}</strong>
                </div>
              )}
              {props.selectedReturningSearchHour && (
                <button
                  className={styles.connectionButton}
                  type="button"
                  disabled={
                    props.selectedReturningSearchHour >= 24 || props.returningTrains.length === 0
                  }
                  onClick={() => searchReturningLaterTrains()}
                >
                  {t(Keys.WORKFLOW.Train.trainConnection.connection.later)} &#9660;
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function renderItem(
    prefix: 'departure' | 'returning',
    i: number,
    name: string,
    item: TrainConnectionItem,
    onSelect: (value: TrainConnectionItem) => void,
    selectedTrain: TrainConnectionItem
  ) {
    const isItemSelected = item.trainID === selectedTrain.trainID;
    const itemKey=`${props.id}_${uID}_${prefix}_${i}_${item.trainID}`;

    return (
        <li
          onClick={() => onSelect(item)}
          className={styles.connectionItemContainer}
          key={props.id + '_' + uID + prefix + i}
        >
          <div className={styles.connectionItem}>
            <div>
              <span className={styles.time}>
                {item.departureTime} - {item.arrivalTime}
              </span>
              &nbsp; |&nbsp;
              <span className={styles.duration}>
                {item.duration}h,&nbsp;{item.changes}{' '}
                {t(Keys.WORKFLOW.Train.trainConnection.connection.change, {
                  count: (item.changes as unknown) as number
                })}
              </span>
            </div>
            <div className={styles.connectionDetail}>
              {item.details.map((detail, index) => (
                  <div key={`${itemKey}_detail_${detail.detID || index}`} className={styles.connectionDetailItem}>{detail.name}</div>
              ))}
            </div>
            <div className={styles.connectionItemConnection}>
              <span>{prefix === 'departure' ? props.returningStation : props.destination}</span>
              <span>{prefix === 'departure'? props.destination : props.returningStation}</span>
            </div>
          </div>
          <div className={styles.connectionItemInput}>
            <Input
              id={props.id + '_' + uID + prefix + '_' + item.trainID + '_connection'}
              type={'radio'}
              name={props.id + '_' + name}
              value={item.trainID}
              checked={isItemSelected}
              onChange={() => onSelect(item)}
              readOnly={false}
            />
            <Label for={props.id + '_' + uID + prefix + '_' + item.trainID + '_connection'}>
              {t(Keys.WORKFLOW.Train.trainConnection.connection.from)}{' '}
              <span className={styles.connectionItemInputPrice}>
                <Price amount={item.price} />
              </span>
            </Label>
          </div>
        </li>
    );
  }
  function searchDepartingEarlierTrains() {
    if (props.selectedDepartingSearchHour && props.onChangeDepartingSearchHour) {
      let newValue = props.selectedDepartingSearchHour - 4;
      if (newValue < 0) newValue = 0;
      props.onChangeDepartingSearchHour(newValue);
    }
  }

  function searchDepartingLaterTrains() {
    if (props.selectedDepartingSearchHour && props.onChangeDepartingSearchHour) {
      let newValue = props.selectedDepartingSearchHour + 4;
      if (newValue > 24) newValue = 24;
      props.onChangeDepartingSearchHour(newValue);
    }
  }

  function searchReturningEarlierTrains() {
    if (props.selectedReturningSearchHour && props.onChangeReturningSearchHour) {
      let newValue = props.selectedReturningSearchHour - 4;
      if (newValue < 0) newValue = 0;
      props.onChangeReturningSearchHour(newValue);
    }
  }

  function searchReturningLaterTrains() {
    if (props.selectedReturningSearchHour && props.onChangeReturningSearchHour) {
      let newValue = props.selectedReturningSearchHour + 4;
      if (newValue > 24) newValue = 24;
      props.onChangeReturningSearchHour(newValue);
    }
  }
};
